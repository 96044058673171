import { ButtonBack, ButtonNext, CarouselProvider, DotGroup } from 'pure-react-carousel'
import styled from 'styled-components'

import { TypeStyle, fromMd } from '@syconium/little-miss-figgy'

import { CrossedOutPrice, DiscountPrice, Price } from '../../PriceDisplay/styles'

export const AddButtonWrapper = styled.div`
  flex: 1;
`

export const Body = styled.div`
  background: ${o => o.theme.color.deprecated.stoneLighter};
  padding: 32px 0 50px;
  position: relative;

  ${fromMd} {
    padding-bottom: 116px;
  }

  & > h2 {
    text-align: center;
  }
`

export const LoadingSpinnerWrapper = styled.div`
  align-items: center;
  background-color: ${o => o.theme.color.deprecated.stoneLighter};
  display: flex;
  height: 237px;
  justify-content: center;
`

export const Title = styled(TypeStyle.HeadlineExtraExtraSmall)`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
`

export const DetailText = styled(TypeStyle.BodyNano)`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  font-size: 11px;
  font-weight: bold;
`

export const Carousel = styled(CarouselProvider)`
  background-color: ${o => o.theme.color.deprecated.stoneLighter};
  margin: auto;

  > div:first-child > div > ul > li > div:focus,
  div:focus-within,
  div {
    outline: none;
  }

  ${fromMd} {
    max-width: 400px;
  }
`

export const Next = styled(ButtonNext)`
  position: absolute;
  inset-inline-end: 40px;
  top: 157px;
  z-index: 10000;

  .rtl & {
    transform: scaleX(-1);
  }
`

export const Back = styled(ButtonBack)`
  inset-inline-start: 40px;
  position: absolute;
  top: 157px;
  z-index: 10000;

  .rtl & {
    transform: scaleX(-1);
  }
`

export const UpsellTile = styled.div`
  background: white;
  margin: 16px;
  padding: 16px;

  ${fromMd} {
    padding: 15px 16px 16px 16px;
  }
`

export const Thumbnail = styled.div<{
  imgUrl: string
}>`
  background: no-repeat center/contain url(${o => o.imgUrl && o.imgUrl});
  height: 84px;
  width: 83px;
  min-width: 83px;
`

export const TileOverview = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline-start: 16px;
  width: 100%;
`

export const DetailsMargins = styled.div`
  margin-bottom: auto;
  margin-top: auto;
`

export const SizeSelectorWrapper = styled.div<{
  dropdownHeight: number
  openingDirection: 'up' | 'down'
}>`
  flex: 1;
  margin-inline-end: 5px;

  & > div:first-child {
    height: 100%;
  }

  ${fromMd} {
    margin-inline-end: 10px;
  }

  & > div > div {
    border-radius: 4px;
    height: 100%;
  }

  & > div > div > :nth-child(3) {
    bottom: ${o => (o.openingDirection === 'up' ? o.dropdownHeight + 'px' : '0')};
    border-width: ${o => (o.openingDirection === 'up' ? '1px 1px 0' : '0 1px 1px')};
  }
`

export const ProductName = styled(TypeStyle.HeadlineExtraExtraSmall)`
  font-size: 13px;
  margin-bottom: 4px;
`

export const PriceAndColor = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  ${CrossedOutPrice},
  ${DiscountPrice},
  ${Price} {
    font-size: 11px;
    line-height: 1.5;
  }
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
`

export const Dots = styled(DotGroup)`
  text-align: center;

  & > button > span {
    background-color: ${o => o.theme.color.deprecated.gray.medium};
    border-radius: 100%;
    display: inline-block;
    height: 4px;
    margin: 4px;
    width: 4px;
  }

  & > button.carousel__dot--selected {
    & > span {
      background-color: black;
      height: 8px;
      width: 8px;
      margin-bottom: 2px;
    }
  }
`
