'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'
import LazyHydrate from 'react-lazy-hydration'

import { Animate } from '@syconium/little-miss-figgy'
import { BaseIcon } from '@syconium/little-miss-figgy/dist/components/Icons/BaseIcon'

import { reportClientError } from '../../../app/_components/chrome/scripts/DataDogRumScript'
import { useFeatureFlag } from '../../../app/_providers/ExperimentationProvider.client'
import { useLocalization } from '../../../app/_providers/LocalizationProvider.client'
import { usePortal } from '../../../app/_providers/PortalProvider.client'
import { useSiteWideMessaging } from '../../../app/_providers/SiteWideMessagingProvider.client'
import { useTranslation } from '../../../app/_providers/TranslationProvider.client'
import { trackEvent } from '../../../lib/analytics'
import {
  formatPrettyCurrency,
  intlPrettyCurrency,
  isStandardEmbroideryItem,
} from '../../../lib/utils'
import { CartContainer } from '../../containers/cart'
import { KitGroup } from '../Cart'
import {
  CheckoutButtonWrapper,
  EmbroideryWarningContainer,
  EmptyCartMessage,
  MiniCartMessage,
  TotalSection,
  TotalSectionDivider,
} from '../Cart/styles'
import { groupKitItems, sortCartItems } from '../Cart/utils'
import { CtaButton } from '../CtaButton'
import { EmbroideryDisclosure } from '../EmbroideryDisclosure'
import { IntlShippingAndTaxMsg } from '../IntlShippingAndTaxMsg'

import { FreeShippingSection } from './FreeShipping'
import { MiniCartItem } from './MiniCartItem'
import { PriceLine } from './PriceLine'
import {
  CartItemsWrapper,
  CheckoutDisclosureText,
  CheckoutDisclosureWrapper,
  CheckoutErrorMessage,
  IntlMsgWrap,
  PortalDiscountText,
  TotalAndCheckout,
  TotalLineItems,
} from './styles'
import { Upsells } from './Upsells'

/**
 * Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
 * @deprecated do not use outside of temporary Product Bundle Discounts work.
 * @todo remove this after we remove this feature.
 */
const rlsusProductGroupHandle = 'womens-ribbed-longsleeve-underscrub' as const

type MiniCartContentProps = {
  isRevealed: boolean
}

export const MiniCartContent = ({ isRevealed }: MiniCartContentProps) => {
  const {
    cart,
    status: cartStatus,
    proceedToCheckout,
    removeItems,
    updateItemsQuantity,
  } = CartContainer.useContainer()

  const { cart: miniCartCopy } = useTranslation()
  const { siteWidePrecheckoutMessage: miniCartMessage } = useSiteWideMessaging()
  const { locale, region, isUSRegion } = useLocalization()
  const { portal, stipend } = usePortal()

  const [isCheckoutInFlight, setIsCheckoutInFlight] = useState<boolean>(false)
  const [checkoutFailed, setCheckoutFailed] = useState<boolean>(false)

  const cartItems = Object.values(cart.items)

  const kitGroups = groupKitItems(cartItems)

  const formattedPrice = intlPrettyCurrency({
    cents: cart.totalPrice,
    region: region,
    locale: locale,
    explicitFormat: true,
  })

  useEffect(() => {
    if (!isRevealed) {
      setCheckoutFailed(false)
    }
  }, [isRevealed])

  const handleCheckoutClick = useCallback(async () => {
    try {
      setIsCheckoutInFlight(true)
      setCheckoutFailed(false)
      await proceedToCheckout()
      setIsCheckoutInFlight(false)
    } catch (error) {
      setCheckoutFailed(true)
      setIsCheckoutInFlight(false)
      reportClientError({
        error,
        context: {
          scope: 'MiniCart',
        },
      })
    }
  }, [proceedToCheckout])

  const showEmbroideryDisclosure = Object.values(cart.items).some(item =>
    isStandardEmbroideryItem(item)
  )

  const hasPortalItemsSelected = useMemo(() => {
    return cartItems.filter(item => item.isPortalColor).length > 0
  }, [cartItems])

  const showFreeReturnsDisclosure = useMemo(() => {
    // no free returns for `FINAL SALE` items (including embroidery)
    if (Object.values(cart.items).some(e => e.finalSale)) return false
    // respect the checks for the embroidery disclosure
    if (showEmbroideryDisclosure) return false
    return isUSRegion
  }, [cart.items, isUSRegion, showEmbroideryDisclosure])

  const freeReturnsExchangesDecision = useFeatureFlag({
    key: 'returns-exchanges-copy-test',
    defaultVariant: 'returns-exchanges-copy-control',
    ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
  })

  const freeReturnsAndExchangesContent = useMemo(() => {
    if (freeReturnsExchangesDecision === 'returns-exchanges-copy-a') {
      // Variant A (icon + text)
      return (
        <>
          <BaseIcon width='20px' height='20px' fill='#282828'>
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M2.29037 6.27244C2.29037 6.12228 2.4444 6.02144 2.58202 6.08149L9.41545 9.06335C9.78735 9.22564 10.21 9.22564 10.5819 9.06335L17.4154 6.08149C17.553 6.02144 17.707 6.12229 17.707 6.27244V14.151C17.707 14.2338 17.6579 14.3088 17.582 14.3419L10.082 17.6146C10.0289 17.6378 9.96851 17.6378 9.91538 17.6146L2.41538 14.3419C2.33945 14.3088 2.29037 14.2338 2.29037 14.151V6.27244ZM3.08195 4.93582C2.11859 4.51545 1.04037 5.22136 1.04037 6.27244V14.151C1.04037 14.7309 1.38395 15.2557 1.91545 15.4876L9.41545 18.7603C9.78735 18.9226 10.21 18.9226 10.582 18.7603L18.082 15.4876C18.6135 15.2557 18.957 14.7309 18.957 14.151V6.27244C18.957 5.22136 17.8788 4.51545 16.9154 4.93582L10.082 7.91768C10.0289 7.94086 9.96851 7.94086 9.91538 7.91768L3.08195 4.93582Z'
              fill='#282828'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M5.60087 3.91353L13.9342 7.24686L14.3984 6.08626L6.0651 2.75293L5.60087 3.91353Z'
              fill='#282828'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M2.29297 14.167L2.29297 5.78792C2.29297 5.70458 2.34264 5.62926 2.41924 5.59643L9.91924 2.38214C9.97164 2.35968 10.031 2.35968 10.0834 2.38214L17.5834 5.59643C17.66 5.62926 17.7096 5.70458 17.7096 5.78792L17.7096 14.167L18.9596 14.167L18.9596 5.78792C18.9596 5.20454 18.612 4.6773 18.0758 4.4475L10.5758 1.23321C10.2089 1.07599 9.79368 1.07599 9.42684 1.23321L1.92684 4.4475C1.39063 4.6773 1.04297 5.20454 1.04297 5.78792L1.04297 14.167L2.29297 14.167Z'
              fill='#282828'
            />
            <path
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M10 8.54199C10.3452 8.54199 10.625 8.82181 10.625 9.16699V18.3337C10.625 18.6788 10.3452 18.9587 10 18.9587C9.65482 18.9587 9.375 18.6788 9.375 18.3337V9.16699C9.375 8.82181 9.65482 8.54199 10 8.54199Z'
              fill='#282828'
            />
            <path
              d='M14.2901 11.6742L15.4682 11.0372C15.6269 10.9507 15.8203 11.0655 15.8203 11.2462V13.1281C15.8203 13.2152 15.7727 13.2954 15.6962 13.3371L14.5181 13.9741C14.3595 14.0606 14.166 13.9458 14.166 13.7651V11.8833C14.166 11.7961 14.2136 11.716 14.2901 11.6742Z'
              fill='#282828'
            />
          </BaseIcon>
          {miniCartCopy.returns.freeReturnsAndExchanges}
        </>
      )
    } else if (freeReturnsExchangesDecision === 'returns-exchanges-copy-b') {
      // Variant B (text)
      return miniCartCopy.returns.freeReturnsAndExchanges
    } else {
      // Control
      return null
    }
  }, [freeReturnsExchangesDecision, miniCartCopy.returns.freeReturnsAndExchanges])

  /**
   * Start :: Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.
   * @deprecated do not use outside of temporary Product Bundle Discounts work.
   * @todo remove this after we remove this feature.
   */
  const hasEnoughRLSUS = useMemo(() => {
    const rlsusCount = cartItems.reduce((accumulator, current) => {
      if (current.productGroupHandle === rlsusProductGroupHandle) {
        accumulator += current.quantity
      }
      return accumulator
    }, 0)
    return rlsusCount >= 3
  }, [cartItems])

  const productBundleDiscountCopyFlag = useFeatureFlag({
    key: 'enable-product-bundle-discount-copy-text',
    defaultVariant: 'hide-discount-copy',
    ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
    skip: !hasEnoughRLSUS,
  })

  const showDiscountAtCheckoutCopy = useMemo(() => {
    return productBundleDiscountCopyFlag === 'show-discount-copy'
  }, [productBundleDiscountCopyFlag])

  // End :: Temporary addition to support Product Bundle Discounts for the Ribbed Longsleeve Underscrub.

  const hasStipend = portal && stipend !== null

  return (
    <>
      <FreeShippingSection cartTotal={cart.totalPrice} />
      {cart.itemCount > 0 ? (
        <CartItemsWrapper>
          {kitGroups.map(group => {
            const key = group.map(o => o.key).join('')
            return (
              <KitGroup
                key={key}
                cartItems={group}
                eventCategory='minicart'
                embroideryLabel={miniCartCopy.embroideryItemLabel}
                cartIsUpdating={cartStatus === 'pending'}
                decrementQuantity={() => {
                  updateItemsQuantity(
                    group.map(subItem => subItem.key),
                    -1
                  )
                }}
                incrementQuantity={() => {
                  updateItemsQuantity(
                    group.map(subItem => subItem.key),
                    1
                  )
                }}
                setQuantity={(newQuantity: number) => {
                  updateItemsQuantity(
                    group.map(subItem => subItem.key),
                    () => newQuantity
                  )
                }}
                removeAll={() => {
                  removeItems(group.map(subItem => subItem.key))
                }}
                removeItem={(key: string) => removeItems([key])}
              />
            )
          })}
          {sortCartItems(cartItems).map((item, index) => (
            <MiniCartItem
              key={`${item.key}-${index}`}
              item={item}
              index={index}
              showDiscountAtCheckoutCopy={
                item.productGroupHandle === rlsusProductGroupHandle && showDiscountAtCheckoutCopy
              }
            />
          ))}
        </CartItemsWrapper>
      ) : (
        <Animate.FadeIn>
          <EmptyCartMessage>{miniCartCopy.emptyCartMessage}</EmptyCartMessage>
        </Animate.FadeIn>
      )}
      <TotalAndCheckout>
        {cart.itemCount > 0 ? <TotalSectionDivider /> : null}
        <TotalSection>
          <EmbroideryWarningContainer>
            {showEmbroideryDisclosure ? (
              <EmbroideryDisclosure style={{ marginBottom: '20px' }} />
            ) : null}
          </EmbroideryWarningContainer>
          {miniCartMessage && <MiniCartMessage>{miniCartMessage}</MiniCartMessage>}
          <TotalLineItems>
            {hasStipend && (
              <PriceLine
                type='stipend'
                label={miniCartCopy.stipendBalanceLabel}
                price={formatPrettyCurrency(stipend * 100)}
                {...trackEvent({
                  category: 'minicart',
                  action: 'snapshot',
                  label: 'stipend balance',
                  value: stipend,
                })}
              />
            )}
            <PriceLine
              type='subtotal'
              label={miniCartCopy.subtotalLabel}
              price={formattedPrice}
              loading={cartStatus === 'pending'}
              {...trackEvent({
                category: 'minicart',
                action: 'snapshot',
                label: 'subtotal',
                value: cart.totalPrice,
              })}
            />

            {hasPortalItemsSelected && !!portal?.discountPercentage && (
              <PortalDiscountText>
                {portal?.name} {portal?.discountPercentage}
                {miniCartCopy.portalDiscountMessage}
              </PortalDiscountText>
            )}
          </TotalLineItems>
          <IntlMsgWrap>
            <IntlShippingAndTaxMsg />
          </IntlMsgWrap>
        </TotalSection>

        <CheckoutButtonWrapper>
          <CtaButton
            allCaps={true}
            block
            data-testid='checkout-button'
            borderless={cartItems.length === 0 || isCheckoutInFlight}
            disabled={cartItems.length === 0 || isCheckoutInFlight}
            isProcessing={isCheckoutInFlight}
            onClick={handleCheckoutClick}
            {...trackEvent({
              category: 'minicart',
              action: 'checkout',
              label: miniCartCopy.proceedToCheckoutCTA,
              value: cart.totalPrice,
            })}
          >
            {miniCartCopy.proceedToCheckoutCTA}
          </CtaButton>
          {checkoutFailed && (
            <CheckoutErrorMessage>{miniCartCopy.checkoutErrorMessage}</CheckoutErrorMessage>
          )}
        </CheckoutButtonWrapper>
      </TotalAndCheckout>
      {showFreeReturnsDisclosure && (
        <CheckoutDisclosureWrapper>
          <CheckoutDisclosureText>{freeReturnsAndExchangesContent}</CheckoutDisclosureText>
        </CheckoutDisclosureWrapper>
      )}
      {isRevealed && (
        <LazyHydrate whenVisible>
          <Upsells />
        </LazyHydrate>
      )}
    </>
  )
}
